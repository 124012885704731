import vx, { ajax } from "@vx/framework";
import Template from "./HeaderTemplate.vtl";
import NavigationController from "../navigation/NavigationController";

const salesUnitPath = "light/login/salesunit";

export default function HeaderController() {
  const self = this;
  self.template = Template;
  self.canRemoveEvents = false;

  // Pierwsze 3 id są dla testów reszta to prawdziwi użytkownicy Feniks Finanse Spółka do pilotażowego wdrożenia Platin Light
  const EmployeeIdsForPilot = ["Q0D1F61A", "Q700200E", "I0901610", "Q0D210E2", "Q0D1F1AD", "Q190F00B", "Q0D776FD", "Q190F004", "Q0D7735F", "Q190F001", "Q1CC5001", "Q190F00F",
    "Q1919009", "Q190F014", "Q0E4C199", "Q190F027", "Q190F022", "Q03CF001", "Q3A51001", "Q0F1316D", "Q2FCF001", "Q0E68001", "Q190F01A", "Q377B136",
    "Q0D77909", "Q190F026", "Q0E96001", "Q0D77104", "Q5ABD001", "Q190F00D", "Q190F00E", "Q190F013", "Q260D001", "Q190F00C", "Q0D77B3F", "Q190F01L",
    "Q0D774A6", "Q16A4009", "Q0D772B2", "Q0D774AA", "Q0D774AD", "Q190F00S", "Q0D1F0D8", "Q2211001", "Q0D77AD9", "Q190F00P", "Q0D77ECF", "Q0D77C9A",
    "Q190F00O", "Q1848019", "Q190F00R", "Q184801A", "Q0D77D74", "Q190F021", "Q3F83001", "Q0D3F00F", "Q0D776F1", "Q190F007", "Q190F00L", "Q190F017",
    "Q190F005", "Q0E83001", "Q190F012", "Q190F02K", "Q190F02F", "Q190F02C", "Q190F02J", "Q1B5B028", "Q190F02L", "Q0E4C00C", "Q190F02I", "Q190F02E",
    "Q190F02G", "Q190F02H", "Q0D77A3E", "Q190F02D", "Q188F017", "Q190F02A", "Q0D71001", "Q377B17C", "Q54CF003", "Q0D77175", "Q0D1F4E3", "Q377B1A7",
    "Q0D77564", "Q0D770EF", "Q190F028", "Q190F01W", "Q0D77C00", "Q0164001", "Q6896001", "Q0D0D0CT", "Q0D77293", "Q0C5F001", "Q0D7717E", "Q0D7774B",
    "Q0F131AA", "Q0E4C089", "Q3D3D054", "Q55E700G", "Q190F01G",
  ]

  async function getUserDetails() {
    const user = await self.app.to(self.app.context.currentEmployee.getMe());

    // Pilot do nowego platin light
    if (EmployeeIdsForPilot.includes(user.employeeId)) {
      window.location.href = window.location.href.replace(/\/host/, "\/hostnew").replace(/\/#welcome/, "\/#welcomenew")
    }

    self.userData = {
      firstName: user.firstName,
      surname: user.surname,
      selectedUnit: user.salesUnits.find(
        el => el.statisticalUnitNumber === user.lastUnitNumber
      ),
      id: user.employeeId,
      availableUnits: user.salesUnits
    };
  }

  function rerender() {
    self.render();
    // debugger;
    setTimeout(() => {
      vx.load(NavigationController, {
        areaId: self.menuId,
        abortable: false
      });
    }, 0);
  }

  async function changeUserUnit(event) {
    const newUnitId = event.target.getAttribute("vx-unit-number");
    const newToken = (
      await self.app.to(
        ajax.post(salesUnitPath, null, {
          statisticalUnitNumber: newUnitId
        })
      )
    ).token;
    sessionStorage.removeItem("platinEncodedToken");
    sessionStorage.removeItem("user");
    sessionStorage.setItem("platinToken", newToken);

    window.location.reload();
  }

  self.init = async function init() {
    self.menuId = "menu";

    await getUserDetails();
    rerender();
  };

  self.events = {
    "#unitListId click": changeUserUnit
  };
}
